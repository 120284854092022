<template>
  <div class="privacy">
    <h1>Privacy Policy</h1>
    <p>
      John Shum built the Design Board app as a Freemium app. This SERVICE is
      provided by John Shum at no cost and is intended for use as is.
    </p>
    <p>
      This page is used to inform visitors regarding my policies with the
      collection, use, and disclosure of Personal Information if anyone decided
      to use my Service.
    </p>
    <p>
      If you choose to use my Service, then you agree to the collection and use
      of information in relation to this policy. The Personal Information that I
      collect is used for providing and improving the Service. I will not use or
      share your information with anyone except as described in this Privacy
      Policy.
    </p>
    <p>
      The terms used in this Privacy Policy have the same meanings as in our
      Terms and Conditions, which is accessible at Design Board unless otherwise
      defined in this Privacy Policy.
    </p>
    <br />
    <h2>Information Collection and Use</h2>
    <br />
    <p>
      For a better experience, while using our Service, I may require you to
      provide us with certain personally identifiable information, including but
      not limited to email, Google oAuth2 credentials and google sheet IDs. The
      information that I request will be retained on your device and is not
      collected by me in any way. The app uses third party services that may
      collect information used to identify you.
      <br />
      <br />
      The app uses third party services that may collect information used to
      identify you. Links to privacy policies of third party service providers
      used by Design Board:
    </p>
    <br />
    <ul>
      <li>
        <a href="https://policies.google.com/privacy"
          >Google Sign-in and Google Sheet Services</a
        >
      </li>
      <li><a href="https://www.figma.com/privacy/">Figma</a></li>
    </ul>
    <br />
    <p>
      I only collect your email from Google to display on the login page about
      your login status. The oAuth token and sheet ids are used for the plugin
      flows in Figma to view/edit/add rows in your linked Google sheet.
    </p>
    <!---->
    <br />

    <h2>Log Data</h2>
    <br />
    <p>
      I want to inform you that whenever you use Design Board, in a case of an
      error (and sometimes in normal operation) in the app we collect data and
      information on your mobile device and/or in our servers called Log Data.
      This Log Data may include information such as your device Internet
      Protocol (“IP”) address, device name, operating system version, the
      configuration of the app when utilizing our Design Board, the time and
      date of your use of Design Board, and other statistics.
    </p>
    <br />
    <h2>Cookies</h2>
    <br />

    <p>
      Cookies are files with a small amount of data that are commonly used as
      anonymous unique identifiers. These are sent to your browser from the
      websites that you visit and are stored on your device's internal memory.
    </p>
    <p>
      This Service does not use these “cookies” explicitly. However, the app may
      use third party code and libraries that use “cookies” to collect
      information and improve their services. You have the option to either
      accept or refuse these cookies and know when a cookie is being sent to
      your device. If you choose to refuse our cookies, you may not be able to
      use some portions of this Service.
    </p>
    <br />

    <h2>Service Providers</h2>
    <br />
    <p>
      I may employ third-party companies and individuals due to the following
      reasons:
    </p>
    <br />
    <ul>
      <li>To facilitate our Service;</li>
      <li>To provide the Service on our behalf;</li>
      <li>To perform Service-related services; or</li>
      <li>To assist us in analyzing how our Service is used.</li>
    </ul>
    <br />
    <p>
      I want to inform users of this Service that these third parties have
      access to your Personal Information. The reason is to perform the tasks
      assigned to them on our behalf. However, they are obligated not to
      disclose or use the information for any other purpose.
    </p>
    <br />

    <h2>Security</h2>
    <br />
    <p>
      I value your trust in providing us your Personal Information, thus we are
      striving to use commercially acceptable means of protecting it. But
      remember that no method of transmission over the internet, or method of
      electronic storage is 100% secure and reliable, and I cannot guarantee its
      absolute security.
    </p>
    <br />

    <h2>Links to Other Sites</h2>
    <br />
    <p>
      This Service may contain links to other sites. If you click on a
      third-party link, you will be directed to that site. Note that these
      external sites are not operated by me. Therefore, I strongly advise you to
      review the Privacy Policy of these websites. I have no control over and
      assume no responsibility for the content, privacy policies, or practices
      of any third-party sites or services.
    </p>
    <br />

    <h2>Children’s Privacy</h2>
    <br />
    <p>
      These Services do not address anyone under the age of 13. I do not
      knowingly collect personally identifiable information from children under
      13 years of age. In the case I discover that a child under 13 has provided
      me with personal information, I immediately delete this from our servers.
      If you are a parent or guardian and you are aware that your child has
      provided us with personal information, please contact me so that I will be
      able to do necessary actions.
    </p>
    <br />

    <h2>Changes to This Privacy Policy</h2>
    <br />
    <p>
      I may update our Privacy Policy from time to time. Thus, you are advised
      to review this page periodically for any changes. I will notify you of any
      changes by posting the new Privacy Policy on this page.
    </p>
    <p>This policy is effective as of 2021-03-28</p>
    <br />

    <h2>Contact Us</h2>
    <br />
    <p>
      If you have any questions or suggestions about my Privacy Policy, do not
      hesitate to contact me at john@shum.net.
    </p>
    <br />
    <p>
      This privacy policy page was created at
      <a
        href="https://privacypolicytemplate.net"
        target="_blank"
        rel="noopener noreferrer"
        >privacypolicytemplate.net </a
      >and modified/generated by
      <a
        href="https://app-privacy-policy-generator.nisrulz.com/"
        target="_blank"
        rel="noopener noreferrer"
        >App Privacy Policy Generator</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "Privacy",
};
</script>

<style scoped>
.privacy {
  text-align: justify;
  padding-left: 20rem;
  padding-right: 20rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
h1 {
  color: black;
}
li {
  font-size: 1.2rem;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&family=Open+Sans&family=Roboto:wght@100;400&display=swap");

h1 {
  font-size: 6rem;
  font-family: "Roboto", sans-serif;
  color: black;
  vertical-align: middle;
  display: table-cell;
  font-weight: 100;
}

h2 {
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  color: black;
  vertical-align: middle;
  display: table-cell;
}

ul {
  list-style-type: circle;
  margin-left: 5vw;
}
a {
  color: blue;
  text-decoration: underline;
}
p {
  font-family: "Roboto", sans-serif;
  color: #000000;
  font-size: 1.2vw;
}
.small-text {
  font-size: 1.1vw;
}
.main-button:disabled {
  cursor: not-allowed;
}
.small-button:disabled {
  cursor: not-allowed;
}
.main-button {
  background: black;
  color: white;
  border: 2px solid #000207;
  border-radius: 10px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: 1vw;
  line-height: 2;
  min-width: 20%;
  margin-top: 2vh;
}
svg {
  margin: 3vh;
}
.right {
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}
.main-button:hover {
  background: white;
  color: black;
  border: 2px solid #000207;
  border-radius: 10px;
}
.small-button:hover {
  background: rgb(0, 0, 0);
  color: #ffffff;
}
.el-aside {
  background-color: #000000;
  color: #333;
  text-align: center;
  line-height: 200px;
  height: 100vh;
  display: table;
}

.el-main {
  background-color: #ffffff;
  text-align: center;
  display: table;
}
.icon {
  width: 1rem;
  height: 1rem;
  float: left;
}
.borderless {
  border: none;
  font-size: 2vw;
  margin-left: 1vw;
  background-color: rgba(255, 250, 250, 0);
  background-color: rgba(255, 250, 250, 0);
}

.borderless:hover {
  color: #000;
  background-color: rgba(255, 250, 250, 0);
}

.bordered {
  margin-top: 2vh;
  border: 1px dashed #363636;
  border-radius: 10px;
}

.sheet-input {
  width: 80% !important;
  border: 2px black solid;
  border-radius: 5px;
}

.el-input__inner {
  border: 1px solid #dcdfe600 !important;
}

#email {
  width: 22vw;
  overflow-x: scroll;
  margin-left: 2vh;
}

::-webkit-scrollbar {
  background-color: transparent;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  height: 0px;
}
</style>