import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import { VueClipboard } from '@soerenmartius/vue3-clipboard';
import Toaster from '@meforma/vue-toaster';
import VueCountdown from '@chenfengyuan/vue-countdown';

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

// layouts

import Auth from "@/layouts/Auth.vue";

// views for Admin layout


// views for Auth layout
import Register from "@/views/auth/Register.vue";

import Privacy from "@/Privacy.vue";


// views without layouts

import Index from "@/views/Index.vue";

// routes

const routes = [
  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/register",
        component: Register,
      },
    ],
  },
  {
    path: "/",
    component: Index,
  },
  {
    path: "/privacy",
    component: Privacy,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App)
app.use(VueClipboard)
app.use(Toaster)
app.use(router)
app.component(VueCountdown.name, VueCountdown);
app.mount("#app");
