<template>
  <main-content>
    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-6/12 px-4">
          <div class="
              relative
              flex flex-col
              min-w-0
              break-words
              w-full
              mb-6
              shadow-lg
              rounded-lg
              bg-blueGray-200
              border-0
            ">
            <div v-if="!loggedIn">
              <div class="rounded-t mb-0 px-6 py-6">
                <div class="text-center mb-3">
                  <h6 class="text-blueGray-500 text-lg font-bold">
                    Design Board Token Generator
                  </h6>
                </div>
                <hr class="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form>
                  <div class="relative w-full mb-3">
                    <label class="
                        block
                        uppercase
                        text-blueGray-600 text-xs
                        font-bold
                        mb-2
                      " htmlFor="grid-password">
                      Sheet Link
                    </label>
                    <input class="
                        border-0
                        px-3
                        py-3
                        placeholder-blueGray-300
                        text-blueGray-600
                        bg-white
                        rounded
                        text-sm
                        shadow
                        focus:outline-none
                        focus:ring
                        w-full
                        ease-linear
                        transition-all
                        duration-150
                      " placeholder="Enter your sheeet link here" v-model="sheetLink" />
                  </div>

                  <div class="center">
                    <div ref="googleLoginBtn" />
                  </div>
                </form>
              </div>
            </div>
            <div v-if="
              hash && loggedIn
            ">
              <div class="rounded-t mb-0 px-6 py-6">
                <div class="grid grid-cols-2 gap-4">
                  <div class="text-left mb-3">
                    <vue-countdown :time="60 * 60 * 1000" v-slot="{ minutes, seconds }">
                      Token expires in
                      <b>{{ minutes }} minutes, {{ seconds }} seconds </b>
                    </vue-countdown>
                  </div>
                  <div class="text-right mb-3">
                    <button @click="handleClickSignOut" class="
                        bg-darkPurple-500
                        text-white
                        active:bg-emerald-600
                        text-xs
                        font-bold
                        uppercase
                        px-4
                        py-2
                        rounded
                        shadow
                        hover:shadow-lg
                        outline-none
                        focus:outline-none
                        lg:mr-1
                        lg:mb-0
                        ml-3
                        mb-3
                        ease-linear
                        transition-all
                        duration-150
                      " type="button">
                      Sign out
                    </button>
                  </div>
                </div>
                <hr class="border-b-1 border-blueGray-300" />
              </div>
              <p class="px-6 py-6">
                You're signed in as,
                <b><i>{{ user }}.</i></b>
                Use the token below to login to the Figma plugin,
              </p>
              <div class="px-6 py-6">
                <div>
                  <p id="email">
                    <b>{{ hash }}</b>
                  </p>
                </div>
                <div class="text-center mt-6">
                  <button class="
                      bg-blueGray-800
                      text-white
                      active:bg-blueGray-600
                      text-sm
                      font-bold
                      uppercase
                      px-6
                      py-3
                      rounded
                      shadow
                      hover:shadow-lg
                      outline-none
                      focus:outline-none
                      mr-1
                      mb-1
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    " type="button" @click="copyEmail()">
                    Copy token
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main-content>
</template>
<script>
import { toRefs } from "vue";
import { toClipboard } from "@soerenmartius/vue3-clipboard";
import CryptoJS from "crypto-js";
import jwt_decode from "jwt-decode";

const key = "82f2ceed4c503896c8a291e560bd4325";
const iv = "thisisacoolapp";

export default {
  name: "Login",
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      loggedIn: false,
      user: null,
      oAuth: null,
      sheetLink: null,
      hash: null,
      gClient: null,
      gClientId: '1031735595820-4ekmj0umn9jm38qr7qkvqlt6ta34gmfe.apps.googleusercontent.com'
    };
  },
  methods: {
    async handleCredentialResponse(response) {
      try {
        const resp = jwt_decode(response['credential']);
        this.user = resp['email'];
        localStorage.setItem("user", this.user);
        this.gClient.requestAccessToken();
      } catch (error) {
        //on fail do something
        if (error) console.error(error);
        else console.error(error);
        return null;
      }
    },
    async handleOauthToken(response) {
      try {
        this.oAuth = response['access_token'];
        localStorage.setItem("oAuth", this.oAuth);
        this.sheetLink = this.sheetLink.match(
          /.*[^-\w]([-\w]{25,})[^-\w]?.*/
        )[1];
        localStorage.setItem("sheetLink", this.sheetLink);
        const hashKey = JSON.stringify({
          oAuth: this.oAuth,
          user: this.user,
          sheetId: this.sheetLink,
        });
        this.hash = this.aesEncrypt(hashKey);
        localStorage.setItem("hash", this.hash);
        this.loggedIn = true;
      } catch (error) {
        //on fail do something
        if (error) console.error(error);
        else console.error(error);
        return null;
      }
    },
    loginInit(gClientId) {
      this.gClient = window.google.accounts.oauth2.initTokenClient({
        client_id: gClientId,
        scope: 'https://www.googleapis.com/auth/spreadsheets.readonly https://www.googleapis.com/auth/spreadsheets',
        callback: this.handleOauthToken,
      })
      window.google.accounts.id.initialize({
        client_id: gClientId,
        callback: this.handleCredentialResponse,
      })
      window.google.accounts.id.renderButton(
        this.$refs.googleLoginBtn, {
        text: 'Login',
        size: 'large',
        theme: 'filled_black' // option : filled_black | outline | filled_blue
      })
    },
    aesEncrypt(txt) {
      const cipher = CryptoJS.AES.encrypt(txt, CryptoJS.enc.Utf8.parse(key), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
      });

      return cipher.toString();
    },
    aesDecrypt(txt) {
      const cipher = CryptoJS.AES.decrypt(txt, CryptoJS.enc.Utf8.parse(key), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
      });

      return CryptoJS.enc.Utf8.stringify(cipher).toString();
    },
    async handleClickSignIn() {
      if (this.sheetLink == null) {
        this.$toast.show(`Please enter a sheet link to log in`, {
          position: "top",
          type: "error",
        });
        return null;
      }
    },
    async handleClickSignOut() {
      try {
        localStorage.clear();
        this.loggedIn = false;
        this.user = null;
        this.oAuth = null;
        this.hash = null;
        this.sheetLink = null;
        localStorage.clear();
        location.reload()
      } catch (error) {
        console.error(error.response);
      }
    },
    copyEmail() {
      var copyText = document
        .getElementById("email")
        .getElementsByTagName("b")[0].innerHTML;
      toClipboard(copyText);
    },
  },
  setup(props) {
    const { isSignIn } = toRefs(props);
    const handleClickLogin = () => { };
    return {
      handleClickLogin,
      isSignIn,
      toClipboard,
    };
  },
  created() {
    let that = this;
    try {
      that.user = localStorage.getItem("user");
      that.oAuth = localStorage.getItem("oAuth");
      that.hash = localStorage.getItem("hash");
      that.sheetLink = localStorage.getItem("sheetLink");
    } catch (e) {
      console.log(e);
    }
  },
  mounted() {
    this.sheetLink = null;
    this.loginInit(this.gClientId);
  }
};
</script>
<style scoped>
.search-list {
  max-height: 20vh;
  overflow-y: scroll;
}

.google {
  height: 6vh;
  margin-left: 30%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
