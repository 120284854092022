<template>
  <div>
    <index-navbar class="bg-blueGray-800"/>
    <main>
      <section class="relative w-full h-full bg-blueGray-800 py-40 min-h-screen">
        <router-view />
        <footer-small absolute />
      </section>
    </main>
  </div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterSmall from "@/components/Footers/FooterSmall.vue";

export default {
  data() {
    return {
    };
  },
  components: {
    IndexNavbar,
    FooterSmall,
  },
};
</script>
